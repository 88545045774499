.footer-row {
    background-color: #000000;
    color: #ffffff;
    clear: both;
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 80px;
    min-width: 446px;
    border-top-style:solid;border-width:1px 0;border-color:#ccc;
    border-bottom-style:solid;border-width:1px 0;border-color:#ccc;

}