@import url('https://fonts.googleapis.com/css?family=Pangolin');
.header-banner-text{
    font-family: Pangolin, cursive;
    font-size: 30px;
    color: #ffffff;
    margin: auto;
    line-height: 55px;
    text-shadow: 1px 1px 1px #5e5e5e;

}

.header-banner-row{
    border-top-style:solid;border-width:1px 0;border-color:#ccc;
    border-bottom-style:solid;border-width:1px 0;border-color:#ccc;
}
.header-banner-yellow{
    background-color: #f7db07;
}
.header-banner-red{
    background-color: #ed969e;
}

@media (max-width: 576px) {
    header-banner-text {
        font-size: 15px;
    }
}

