.faq-background-container {
    width: 100%;
    /*background: url('/images/background-cement-concrete-242236.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    min-height: 500px;
}

.toggle-icon-container{
    width: 30px;
    height: 14px;
    display: inline-block;
}

.collapse.plus-toggle-icon.show,
.collapsing.plus-toggle-icon:not(.show),
.collapsing.minus-toggle-icon.show {
    display: none;
}

.collapse.plus-toggle-icon:not(.show) {
    display: inline-block;
}

.plus-toggle-icon, .minus-toggle-icon {
    position: absolute;
    top: -1px;
    font-size: 1.8em;
    margin-right: 6px;
}