@import '../../variables';
@import url('https://fonts.googleapis.com/css?family=Pangolin');
.preview-banner-text{
    font-family: Pangolin, cursive;
    font-size: 42px;
    color: $blue;
    margin: auto;
    line-height: 64px;
    text-shadow: 1px 1px 1px #5e5e5e;

}

.preview-banner-row{
    background-color: #ffffff;
    border-top-style:solid;border-width:1px 0;border-color:#ccc;
    border-bottom-style:solid;border-width:1px 0;border-color:#ccc;

}
@media (max-width: 576px) {
    .preview-banner-text {
        font-size: 22px;
    }
}

