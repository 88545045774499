@import '../../variables';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

$gift-box-size: 170px;
$gift-box-disabled-size: 125px;

.redeem-now-transition-group-enter {
    opacity: 0.01;
}

.redeem-now-transition-group-enter.redeem-now-transition-group-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.redeem-now-transition-group-leave {
    opacity: 1;
}

.redeem-now-transition-group-leave.redeem-now-transition-group-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.redeem-now-transition-group-appear {
    opacity: 0.01;
}

.redeem-now-transition-group-appear.redeem-now-transition-group-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.redeem-now-container {
    //width: 100%;
    //background: url('/images/art-blur-close-up-6334-2.jpg') no-repeat center center fixed;
    //-webkit-background-size: cover;
    //-moz-background-size: cover;
    //-o-background-size: cover;
    //background-size: cover;
    //min-height: calc(100vh - 215px);
}

.background-container {
    width: 100%;
    position: fixed;
    top: 109px;
    bottom: -80px;
}


.background-image {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    margin: auto;
    min-width: 100%;
    min-height: 50%;
}

.redeem-now-card-entry{
    min-height: 436px;
    height: 100%;
}

.fun-ideas-container{
    min-height: 396px;
    height: 100%;
}

@media screen and (max-width: 950px) {
    .background-image {
        top: 55px;
    }
}

@media screen and (max-width: 690px) {
    .background-image {
        top: 120px;
    }
}

.redeem-now-header {
    color: $blue;
    white-space: pre-wrap;
}

.redeem-now-text {
    color: $blue;
}
.redeem-now-text span{
    font-style: italic;
}



/* category selection--------------------*/
.header-text {

}

/*Category display-------------*/
.category-container {

    cursor: pointer;
}
.category-title {
    font-size: 1.2em;
}
.category-image{

}

.category-text {
    font-size: 0.8em;
}


/* charity selection--------*/
.charity-selection-container {

}

.charity-selection-options {
    cursor: pointer;
}


/* thank you--------------*/
.heart-image {
    max-width: 200px;
}

.redeem-now-thank-you-header{
    color: $blue;
    font-size: 2.6em;
}
.redeem-now-thank-you-text{
    color: $blue;
    font-size: 1.6em;
}

.charity-card-container{
    margin-bottom: 24px;
}

.charity-card{
    width: 100%;
    margin-bottom: 30px;
    border-radius: 8px;
    //color: rgba(0, 0, 0, 0.87);
    background: #fff;

}
.charity-card-image-container{
    height: 240px;
}
.charity-card-image{
    display: inline-block;
    width: auto;
    max-width: 95%;
    max-height: 220px;
    border-radius: 8px;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.charity-card-image img {
    border-radius: 8px;
    pointer-events: none;
}

@media (max-width: 1300px) {
    .charity-card-image-container{
        height: 200px;
    }
    .charity-card-image{
        max-height: 180px;
    }
}
@media (max-width: 1250px) {
    .charity-card-image-container{
        height: 160px;
    }
    .charity-card-image{
        max-height: 140px;
    }
}
.charity-card-header{
    color: $blue;
}

.redeem-now-token-image{
    max-width: 95%;
    max-height: 240px;
    margin: 6px 0 16px 0;
}

.emphasis-text{
    font-size: 1.4em;
    color: #FFBA11;
    opacity: .9;

}

.recaptcha-or-submit{
    min-height: 110px;
}
.recaptcha-or-submit div div div {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
    margin: auto;
}

#slideCarousel{
    height: 410px;
}

.redeem-now .charity-card{
    height: 100%;
    margin-bottom: 0;
}

.card-flipped{
    transform: rotateY(180deg);
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}
.card-not-flipped{
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}
/*.charity-card .flip {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}*/
/*
.front,
.back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
}*/

.front {
    z-index: 2;
    transform: rotateY(0deg);
    left: 0;
}

.back {
    transform: rotateY(180deg);
    right: 0;
}


.charity-card button{
    min-width: 60%;
    width: 95%;
    margin: 0;

}
.more-info-button{
}
.description-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 26px;
}
.description-long {
    height: 240px;
    overflow: auto;
}
@media (max-width: 1300px) {
    .description-long {
        height: 200px;
    }
}
@media (max-width: 1250px) {
    .description-long {
        height: 160px;
    }
}

.charity-select-image-container, .charity-select-image-container img{
    max-height: 300px;
}

.token-image-container{
}
.token-image-container img{
    max-height: 120px;
    margin: auto;
}


/*thank you card buttons*/
.thank-you-lower-card{
    height: 100px;
}
@media screen and (max-width: 768px) {
    .thank-you-lower-card{
        height: 160px;
    }
}

.thank-you-lower-card img{
    max-width: 80px;
    min-width: 60px;
    height: auto;
}

.code-entered {
    height: $gift-box-size;
    img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-height: $gift-box-size;
        opacity: 1;
        transition: opacity 500ms ease-in, max-height 500ms ease-in;
        cursor: pointer;
    }
    .background-glow{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 0;
        width: 0;
        z-index: -10;
        opacity: 1;
        transition: opacity 1s ease-in;
        box-shadow: 0 0 $gift-box-size 60px $yellow;
    }
    .click-here{
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -10;
        opacity: 1;
        transition: opacity 1s ease-in;
        cursor: pointer;
        font-size: 1.5rem;
        min-width: 110px;
    }
    .shimmer {
        text-align: center;
        color: rgba(255,255,255,0.1);
        background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
        background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
        background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
        -webkit-background-size: 60px 100%;
        -moz-background-size: 60px 100%;
        background-size: 60px 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-animation-name: shimmer;
        -moz-animation-name: shimmer;
        animation-name: shimmer;
        -webkit-animation-duration: 2s;
        -moz-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-color: #222;
    }
    @-moz-keyframes shimmer {
        0% {
            background-position: top left;
        }
        100% {
            background-position: top right;
        }
    }
    @-webkit-keyframes shimmer {
        0% {
            background-position: top left;
        }
        100% {
            background-position: top right;
        }
    }
    @-o-keyframes shimmer {
        0% {
            background-position: top left;
        }
        100% {
            background-position: top right;
        }
    }
    @keyframes shimmer {
        0% {
            background-position: top left;
        }
        100% {
            background-position: top right;
        }
    }
}

.code-not-entered {
    height: $gift-box-size;
    max-height: 100%;
    vertical-align: middle;
    img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-height: $gift-box-disabled-size;
        opacity: .4;
        transition: opacity 500ms ease-in, max-height 500ms ease-in;
    }
    .background-glow{
        position: absolute;
        top: 10px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 0;
        width: 0;
        z-index: -10;
        opacity: 0;
        transition: opacity 500ms ease-in;
        box-shadow: 0 0 $gift-box-disabled-size 40px $yellow;
    }
}

.redeem-now-code-entry{
    .invalid-feedback{
        position: absolute;
        text-align: left !important;
        margin: 8px 0 0 8px;
    }
}

.present-box-image{
    max-width: 100%;
}