.navbar-container {
    min-height: 70px;
    border-top-style:solid;border-width:1px 0;border-color:#ccc;
    border-bottom-style:solid;border-width:1px 0;border-color:#ccc;
}
.navbar-logo-container{
    height: 100%;
    width: 300px;
    min-height: 100px;
}
.navbar-main {
    width: 100%;
}

.nav-item {
    margin: 0 14px 0 14px;
    cursor: pointer;
    font-size: 1.3em;
}
.nav-item.active{
    color: #F7DB07;
}

.logo {
    position:absolute;
    top: 0;
    left: 0;
    margin: 0 0 0 9px;
    padding: 6px;
    cursor: pointer;
    z-index:2;
    max-height: 100%;
    max-height: 100px;
}

@media (max-width: 950px) {
    .navbar-container {
        min-height: 110px;
    }
    .logo{
        max-width:220px;
    }
    .navbar-logo-container{
        min-height: 100px;
        width: 220px;
    }
}

@media (max-width: 768px) {
    .logo{
        max-width:350px;
    }
    .navbar-logo-container{
        min-height: 100px;
        width: 350px;
    }
}

@media (max-width: 600px) {
    .logo{
        max-width:300px;
    }
    .navbar-logo-container{
        min-height: 100px;
        width: 300px;
    }
}

@media (max-width: 400px) {
    .logo{
        max-width:280px;
    }
    .navbar-logo-container{
        min-height: 80px;
        width: 200px;
    }
}

@media (max-width: 350px) {
    .logo{
        max-width:230px;
    }
    .navbar-logo-container{
        min-height: 80px;
        width: 160px;
    }
}

@media (max-width: 300px) {
    .logo{
        max-width:180px;
    }
    .navbar-logo-container{
        min-height: 80px;
        width: 140px;
    }
}

