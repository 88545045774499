@import '../../variables';

.faq-background-container{
    width: 100%;
    min-height: 500px;
}

.fun-ideas-card-image{
    opacity: 1;
}

.fun-ideas-card{
    color: $blue;
    font-size: 1.2em;
}
