@import url('https://fonts.googleapis.com/css?family=Coming+Soon');

.title-bar-text {
    font-family: 'Coming Soon', cursive;
    font-size: 86px;
    margin: auto;

}
@media (max-width: 576px) {
    .title-bar-text {
        font-size: 43px;
    }
}

