@import "../../variables";

.header-image {
  max-width: 100%;
}

.about-us-container {
  //width: 100%;
  //background: url('/images/about-us-background.jpg') no-repeat center center fixed;
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;
  //min-height: calc(100vh - 215px);

  .about-us-column {
    margin-bottom: 16px;
  }

  .card {
    min-height: 260px;
    height: 100%;
  }
}

.about-us-special-thanks {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 10px;
}
