.home-background-container{
    width: 100%;
    /*background: url('/images/boy-child-clouds-346796.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.1);*/
}
.card-image {
    width: 100%;
    max-width: 480px;
    margin: 10px 6px 20px 6px;
}


.home-images {
    max-width: 100%;
}

.step-container {
    min-height: 250px;
    padding: 20px;
    margin: 0 auto 30px auto;
    flex-direction: inherit;
}

.step-image{
    width: 100%;
    margin: auto;
    max-width: 160px;
    min-width:150px;
}

.step-text {
    margin: auto;
}

.step-card{
    flex-direction: row !important  ;
}


.gift-now-button{
    min-width: 240px;
    margin: 0 auto 20px 0;
}

.title-text{
    font-family: 'Crete Round', serif;
}
