@import './variables';
@import url('https://fonts.googleapis.com/css?family=Pangolin|Alegreya+Sans|Crete+Round|Gloria+Hallelujah|Indie+Flower|Permanent+Marker');

.App {
    text-align: center;
    min-width: 446px;
    padding-bottom: 80px;
    height: 100%;
}

#root {
    min-height: 100%;
    position:relative;
    background-color: #F8F8F8;
}
.main-card-body {
    background-color: #F8F8F8;
    margin-bottom: 120px;

}
.main-body-content {
    background-color: #F8F8F8;
}

html {
    height: 100%;
}
html body {
    height: 100%;
    font-family: 'Crete Round', serif;
}

.white-background {
    background-color: #ffffff;
}

.input-label-element{
    background-color: #f7db07;
    cursor: default;
}

.card {
    box-shadow:         10px 10px 4px 4px #fbe965;
    -moz-box-shadow:    10px 10px 4px 4px #fbe965;
    -webkit-box-shadow: 10px 10px 4px 4px #fbe965;
    opacity: .92;
}
.light-opacity{
    opacity: .85;
}

.clickable:hover{
    cursor: pointer;
    opacity: 1;
}

.clickable-card:hover{
    cursor: pointer;
    opacity: 1;
    background-color: #eff0f1;
}

.clickable{
    cursor: pointer;
}

.blue {
    color: $blue;
}

.opacity{
    opacity: 0.92;
}


.checkbox{
    height: 18px;
    width: 18px;
    background: #fbf09b !important;
    color: #fbf09b !important;
}


.clear-both, .clear{
    clear: both;
}

.display-block{
    display: block;
}
.display-none{
    display: none !important;
}

.title-row{
    background-color:#FFFFFF;
    opacity: .90;
    border-top-style:solid;border-width:1px 0;border-color:#ccc;
    border-bottom-style:solid;border-width:1px 0;border-color:#ccc;
    display: block;
    margin: 0;
    padding: 16px 0 16px 0;
    font-size:24px;
    color: $blue;
}

.white-background{
    background-color: #FFFFFF;
}
.white {
    color:white;
}

.radio-input {
    max-width: 30px;
    min-width: 15px;
}

.radio-input-container{
}

.gray-card-background{
    border-radius: 8px;
    padding: 20px;
    background-color: #343838;
    opacity: 0.92;
    color: #FFFFFF;
}

.fake-button {
    border: 1px dashed;
    margin: 20px;
    padding: 8px 0 8px 0;
    font-size: 12px;
    cursor: pointer;
}

.clickable-text {
    cursor: pointer;
}
.clickable-text:hover{
    opacity: 1;
    background-color: #eff0f1;
}

.heart-background{
    width: 100%;
    background: url('/images/art-blur-close-up-6334.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: calc(100vh - 215px);
}

.break-word{
    word-break: break-word;
}