@import '../../variables.scss';
@import url('https://fonts.googleapis.com/css?family=Pangolin|Francois+One|Fredoka+One|Passion+One|Paytone+One|Baloo');

:root {
    --blue-color: $blue;
}

.input-label-element { width: 100%; }

.input-error-message { color: red;}

.about-us-text {
    font-size: 0.9em;
}

.gift-now-background-container{
    width: 100%;
    /*background: url('/images/background-cement-concrete-242236.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
}



/********card css*************/


.card-preview-transition{
    opacity: 0.01;
}
.card-show {
    opacity: 1;
    transition: opacity 2s;
}

.card-hide {
    opacity: 0.01;
}

.card-preview-container {
    position: relative;
    width: 438px;
    height: 560px;
    margin: auto;
    font-family: 'Baloo';
    white-space: pre-wrap;
}
.card-preview-container-small {
    position: relative;
    width: 328px;
    height: 420px;
    margin: auto;
    font-family: 'Baloo';
    white-space: pre-wrap;
}

.card-preview-image {
    width: 408px;
    height: 528px;
}
.card-preview-image-small {
    width: 328px;
    height: 420px;
}

.card-preview-overlay-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 438px;
}
.card-preview-overlay-container-small {
    position: absolute;
    top: 0;
    right: 0;
    width: 328px;
    height: 420px;
    max-width: 328px;
}

.divider-dots{
    font-family: 'Pangolin';
    font-size:34px;
    color: var(--blue-color);
    line-height: 4px;
}
.divider-dots-small{
    font-family: 'Pangolin';
    font-size:25px;
    color: var(--blue-color);
    line-height: 3px;
}

.card-preview-overlay-header-text {
    width: 100%;
    max-width: 338px;
    line-height: 44px;
    color: var(--blue-color);
    margin: 14px 60px 0 60px;
    font-family: 'Baloo';
    word-wrap: break-word;
    overflow: hidden;
    height: 130px;
    vertical-align: middle;
}
.card-preview-overlay-header-text-inner{
    max-width: 338px;
    margin: 0;
}
.card-preview-overlay-header-spacer {
    height: 132px;
}
.card-preview-overlay-header-text-small {
    width: 75%;
    max-width: 253px;
    height: 97px;
    font-size: 24px;
    line-height: 33px;
    color: var(--blue-color);
    margin: 11px 45px 0 45px;
    word-wrap: break-word;
    overflow: hidden;
}

.card-preview-overlay-text {
    width: 100%;
    max-width: 338px;
    font-size: 18px;
    color: var(--blue-color);
    margin: 20px 60px 0 60px;
}
.card-preview-overlay-text-small {
    width: 75%;
    max-width: 253px;
    font-size: 13px;
    color: var(--blue-color);
    margin: 14px 45px 0 45px;
}

.card-preview-overlay-line1 {
    font-size: 14px;
    height: 40px;
    overflow: hidden;
}
.card-preview-overlay-line1-small {
    font-size: 10px;
    height: 30px;
    overflow: hidden;
}

.card-preview-overlay-line2 {
    font-weight: 100;
    font-size: 12px;
    width: 45%;
    padding-right: 22px;
    margin: 10px 60px 0 50%;
}
.card-preview-overlay-line2-small {
    font-weight: 100;
    font-size: 9px;
    width: 45%;
    padding-right: 4px;
    margin: 8px 45px 0 50%;
}
.card-preview-overlay-line3 {
}

.bold-link{
    font-weight:600;
}

.card-preview-card-ring {
    margin: 4px;
    margin-left: 28px;
}
.card-preview-card-ring-small {
    margin: 3px;
    margin-left: 21px;
    height: 148px;
    width: 164px;
}

.card-preview-ring-background-spacer {
    height: 154px;
}
.card-preview-ring-background-spacer-small {
    height: 124px;
}


.no-card-info-container {
    transition-duration: 2s;
    border: dashed  #e9ecef 4px;
    width: 100%;
    height: 560px;
    max-width: 438px;
    margin: auto;
    color: #ffffff;
    background-color: $blue;

}

/*@media screen and (max-width: 768px) {
    .card-preview-container {
        position: relative;
        width: 328px;
        margin: auto;
    }

    .card-preview-overlay-header-text {
        width: 100%;
        max-width: 253px;
        height: 97px;
        font-size: 30px;
        color: white;
        margin: 22px 45px 0 45px;
    }

    .card-preview-overlay-text {
        width: 100%;
        max-width: 253px;
        font-size: 13px;
        color: white;
        margin: 15px 45px 0 45px;
    }

    .card-preview-overlay-line1 {
    }
    .card-preview-card-ring {
        height: 150px;
        margin: 3px;
        margin-left: 22px;
    }
    .card-preview-overlay-line2 {
    }
    .card-preview-overlay-line2 {
    }

    .card-preview-image {
        width: 328px;
    }

}*/

.redemption-preview-text {
    font-family: 'Baloo';
    white-space: pre-wrap;
}

.reset-card-button {
    cursor: pointer;
    font-size: 0.8em;
    color: $blue;
}

.checkout-button{
    width: 200px;
}