.below-input-container{
    min-height: 32px;

}

.info-popup{
    padding: 4px;
    display: inline-block;
    min-width: 34px;
}

.helper-text{
    font-size: 0.8em;
    font-style: italic;
    white-space: pre-wrap;
}

.input-field-label{

}